/* eslint-disable  no-unused-vars*/
import Vue from "vue";
import store from "../store";

/* eslint-disable no-unused-vars */
import VueRouter, { RouteConfig } from "vue-router";
import Dashboard from "../views/dashboard.vue";
import Home from "../views/home.vue";
import MyCalendar from "../views/my-calendar.vue";
import Settings from "../views/settings.vue";
import Lectures from "../views/lectures.vue";
import AllLectures from "../views/all-lectures.vue";
import LiveLecture from "../views/live-lecture.vue";
import MyIntakes from "../views/my-intakes.vue";
import RecordedLecture from "../views/recorded-lecture.vue";
import LectureAttendance from "../views/lecture-attendance.vue";
import EnterEmailVerificationCode from "../views/enter-email-verification-code.vue";
import EnterTOTPVerificationCode from "../views/enter-totp-verification-code.vue";
import UploadToMultipleCohorts from "../views/upload-to-multiple-cohorts.vue";
import UpdateToMultipleCohorts from "../views/update-to-multiple-cohorts.vue";

import CourseWorks from "../views/courseworks/course-works.vue";
import AddCourseWork from "../views/courseworks/add-course-work.vue";
import CourseWorkDetails from "../views/courseworks/course-work-details.vue";
import AnswerSheet from "../views/courseworks/answer-sheet.vue";
import ExamSubmissions from "../views/courseworks/exam-submissions.vue";
import EditCourseWork from "../views/courseworks/edit-course-work.vue";
import ExamStatistics from "../views/courseworks/exam-statistics.vue";
import Tutorials from "../views/tutorials.vue";
import TutorialDetails from "../views/tutorial-detail.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    /* name: 'Dashboard', 
     component: Dashboard */
    redirect: "/dashboard/home",
    meta: { requiresLogin: true },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ "../views/forgot-password.vue"
      ),
    meta: { requiresLogin: false },
  },
  {
    path: "/enter-verification-code",
    name: "EnterVerificationCode",
    component: () =>
      import(
        /* webpackChunkName: "EnterVerificationCode" */ "../views/enter-verification-code.vue"
      ),
    meta: { requiresLogin: false },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "ResetPassword" */ "../views/reset-password.vue"
      ),
    meta: { requiresLogin: false },
  },
  {
    path: "/change-default-password",
    name: "ChangeDefaultPassword",
    component: () =>
      import(
        /* webpackChunkName: "ChangeDefaultPassword" */ "../views/change-default-password.vue"
      ),
    meta: { requiresLogin: false },
  },

  {
    path: "/enter-email-verification-code",
    name: "EnterEmailVerificationCode",
    component: EnterEmailVerificationCode,
  },
  {
    path: "/enter-totp-verification-code",
    name: "EnterTOTPVerificationCode",
    component: EnterTOTPVerificationCode,
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login.vue"),
    meta: { requiresLogin: false },
  },
  {
    path: "/ongoing-lecture/",
    name: "OngoingLecture",
    component: () =>
      import(
        /* webpackChunkName: "OngoingLecture" */ "../views/ongoing-lecture.vue"
      ),
    meta: { requiresLogin: true },
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresLogin: true },

    children: [
      {
        path: "home",
        name: "Home",
        component: Home,
        meta: { requiresLogin: true },
      },

      {
        path: "resources",
        name: "Resources",
        component: () =>
          import(/* webpackChunkName: "resources" */ "../views/resources.vue"),
      },
      {
        path: "tutorials",
        name: "Tutorials",
        component: Tutorials,
      },
      {
        path: "tutorial-details",
        name: "TutorialDetails",
        component: TutorialDetails,
      },
      {
        path: "upload-to-multiple-cohorts",
        name: "UploadToMultipleCohorts",
        component: UploadToMultipleCohorts,
        meta: { requiresLogin: true },
      },
      {
        path: "update-to-multiple-cohorts/:resource_id",
        name: "UpdateToMultipleCohorts",
        component: UpdateToMultipleCohorts,
        meta: { requiresLogin: true },
      },
      {
        path: "my-intakes",
        name: "MyIntakes",
        component: MyIntakes,
        meta: { requiresLogin: true },
      },
      {
        path: "my-calendar",
        name: "MyCalendar",
        component: MyCalendar,
        meta: { requiresLogin: true },
      },
      {
        path: "settings",
        name: "Settings",
        component: Settings,
        meta: { requiresLogin: true },
      },
      {
        path: "lectures",
        name: "Lectures",
        component: Lectures,
        meta: { requiresLogin: true },
      },
      {
        path: "live-lecture",
        name: "LiveLecture",
        component: LiveLecture,
        meta: { requiresLogin: true },
      },
      {
        path: "lectures/all",
        name: "AllLectures",
        component: AllLectures,
        meta: { requiresLogin: true },
      },
      {
        path: "recorded-lecture",
        name: "RecordedLecture",
        component: RecordedLecture,
      },
      {
        path: "lecture-attendance",
        name: "LectureAttendance",
        component: LectureAttendance,
      },
      {
        path: "course-work-details/:courseWorkId",
        name: "CourseWorkDetails",
        component: CourseWorkDetails,
      },
      {
        path: "course-work/:courseWorkSetId",
        name: "CourseWorks",
        component: CourseWorks,
      },
      {
        path: ":courseWorkSetId/course-work/add",
        name: "AddCourseWork",
        component: AddCourseWork,
      },
      {
        path: "course-work/edit/:courseWorkId",
        name: "EditCourseWork",
        component: EditCourseWork,
      },
      {
        path: ":answerId/answer-sheet",
        name: "AnswerSheet",
        component: AnswerSheet,
      },
      {
        path: ":courseWorkId/exam-submissions",
        name: "ExamSubmissions",
        component: ExamSubmissions,
      },
      {
        path: "exam-statistics",
        name: "ExamStatistics",
        component: ExamStatistics,
      },
      {
        path: "examinations",
        name: "ExaminationSets",
        component: () =>
          import(
            /* webpackChunkName: "examination-sets" */ "../views/examinations/examination-sets.vue"
          ),
      },
      {
        path: "examinations/:examSetId",
        name: "Examinations",
        component: () =>
          import(
            /* webpackChunkName: "examination-set-details" */ "../views/examinations/examinations.vue"
          ),
      },
      {
        path: "exams-with-modules-taught-by-lecturer/:examSetId",
        name: "ExamsWithModulesTaughtByLecturer",
        component: () =>
          import(
            /* webpackChunkName: "examinations-taught-by-lecturer" */ "../views/examinations/exams-with-modules-taught-by-lecturer.vue"
          ),
      },
      {
        path: "exam-course-work-prerequisites/:examId",
        name: "ManageCourseWorkPrerequisites",
        component: () =>
          import(
            /* webpackChunkName: "exam-course-work-prerequisites" */ "../views/examinations/examination-course-work-prerequisites.vue"
          ),
      },
      {
        path: "exam-results-management/:examinationId",
        name: "ExaminationResults",
        component: () =>
          import(
            /* webpackChunkName: "exam-results-management" */ "../views/examinations/exam-results-management.vue"
          ),
      },
      {
        path: "exam-mark-sheets/:examinationId",
        name: "ExaminationResultsSheet",
        component: () =>
          import(
            /* webpackChunkName: "exam-results-management" */ "../views/examinations/exam-mark-sheets.vue"
          ),
      },
      {
        path: "course-work-results-management/:courseWorkId",
        name: "course-work-results-management",
        component: () =>
          import(
            /* webpackChunkName: "course-work-results-management" */ "../components/courseworks/course-work-results-management.vue"
          ),
      },
      {
        path: "course-work-sets",
        name: "CourseWorkSets",
        component: () =>
          import(
            /* webpackChunkName: "examination-sets" */ "../views/courseworks/course-work-sets.vue"
          ),
      },
      {
        path: "cafeteria",
        name: "Cafeteria",
        component: () =>
          import(
            /* webpackChunkName: "cafeteria" */ "../views/cafeteria/cafeteria.vue"
          ),
      },
      {
        path: "generate-coupon",
        name: "GenerateCoupon",
        component: () =>
          import(
            /* webpackChunkName: "generate-coupon" */ "../views/cafeteria/generate-coupon.vue"
          ),
      },
      {
        path: "previous-coupons",
        name: "PreviousCoupons",
        component: () =>
          import(
            /* webpackChunkName: "previous-coupons" */ "../views/cafeteria/previous-coupons.vue"
          ),
      },

      //In case the route is anything else
      {
        path: "*",
        redirect: "/dashboard/home",
      },
    ],
  },

  //In case the route is anything else
  {
    path: "*",
    redirect: "/dashboard/home",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0, behavior: "smooth" };
  },
});

router.beforeResolve((to, from, next) => {
  if (to.name === "Login" && store.getters.isLoggedIn) {
    next({ path: "/dashboard/home" });
  }

  next();

  //Check if login is required for Routes with meta data loginRequired!
  // if (to.matched.some((record) => record.meta.requiresLogin)) {
  //   if (!store.getters.isLoggedIn) {
  //     //Redirect to Login Page
  //     next({ path: "/login" });
  //   } else next();
  // } else next();
});

export default router;
