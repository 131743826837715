<template>
  <section>
    <div id="sidebar-article">
      <div style="cursor: pointer">
        <div
          style="
            margin-bottom: 10px;
            margin-top: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <router-link to="/dashboard/home">
            <img
              :src="$store.state.logoImageUrl"
              style="height: 65px"
              alt=""
              class="mt-3 img-fluid"
            />
          </router-link>
        </div>

        <!-- <hr class=""> -->
      </div>

      <div class="theLists" style="">
        <ul style="">
          <li v-on:click="closeNav()">
            <router-link to="/dashboard/home">
              <div class="side-link" :class="{ active: $route.name == 'Home' }">
                <span>
                  <HomeIcon class="svg-icon" />
                  Home</span
                >
              </div>
            </router-link>
          </li>
          <li v-on:click="closeNav()" v-if="!$store.getters.isNonTeachingStaff">
            <router-link to="/dashboard/resources">
              <div
                class="side-link"
                :class="{ active: $route.name == 'Resources' }"
              >
                <span> <FoldersIcon class="svg-icon" /> Resources</span>
              </div>
            </router-link>
          </li>
          <li v-on:click="closeNav()" v-if="!$store.getters.isNonTeachingStaff">
            <router-link to="/dashboard/my-intakes">
              <div
                class="side-link"
                :class="{ active: $route.name == 'MyIntakes' }"
              >
                <span>
                  <UsersIcon class="svg-icon" />
                  My Intakes</span
                >
              </div>
            </router-link>
          </li>

          <li
            v-on:click="closeNav()"
            v-if="!$store.getters.isNonTeachingStaff"
            :class="$store.getters.canExamCRUD ? 'show_list' : 'hide_list'"
          >
            <router-link to="/dashboard/course-work-sets">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'CourseWorkSets' ||
                    $route.name == 'EditCourseWorkSet',
                }"
              >
                <span>
                  <BookOpenIcon class="svg-icon" />
                  Course Work</span
                >
              </div>
            </router-link>
          </li>

          <li
            v-on:click="closeNav()"
            v-if="!$store.getters.isNonTeachingStaff"
            :class="$store.getters.canExamCRUD ? 'show_list' : 'hide_list'"
          >
            <router-link to="/dashboard/examinations">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'ExaminationSets' ||
                    $route.name == 'Examinations' ||
                    $route.name == 'AnswerSheet' ||
                    $route.name == 'ExamStatistics' ||
                    $route.name == 'ExamSubmissions',
                }"
              >
                <span>
                  <GraduationCapIcon class="svg-icon" />
                  Examinations</span
                >
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()" v-if="!$store.getters.isNonTeachingStaff">
            <router-link to="/dashboard/lectures">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'Lectures' ||
                    $route.name == 'AllLectures' ||
                    $route.name == 'LiveLecture',
                }"
              >
                <span>
                  <VideoIcon class="svg-icon" />
                  Lectures</span
                >
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()" v-if="!$store.getters.isNonTeachingStaff">
            <router-link to="/dashboard/tutorials">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'Tutorials' ||
                    $route.name == 'TutorialDetails',
                }"
              >
                <span>
                  <TelevisionIcon class="svg-icon" />
                  Tutorials</span
                >
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()" v-if="!$store.getters.isNonTeachingStaff">
            <router-link to="/dashboard/my-calendar">
              <div
                class="side-link"
                :class="{ active: $route.name == 'MyCalendar' }"
              >
                <span>
                  <ClockIcon class="svg-icon" />
                  Timetable</span
                >
              </div>
            </router-link>
          </li>

          <!-- <li v-on:click="closeNav()">
            <router-link to="/dashboard/cafeteria">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'Cafeteria' ||
                    $route.name == 'GenerateCoupon' ||
                    $route.name == 'PreviousCoupons',
                }"
              >
                <span><i class="el-icon-food"></i> Cafeteria</span>
              </div>
            </router-link>
          </li> -->
        </ul>
      </div>

      <div style="">
        <ul style="">
          <li v-on:click="closeNav()">
            <router-link to="/dashboard/settings">
              <div
                class="side-link"
                :class="{ active: $route.name == 'Settings' }"
              >
                <span>
                  <SettingsIcon class="svg-icon" />
                  Settings</span
                >
              </div>
            </router-link>
          </li>

          <li v-on:click="onLogoutIntent">
            <div class="side-link">
              <span>
                <LogoutIcon class="svg-icon" />
                Logout</span
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import HomeIcon from "@/assets/icons/svg/home.icon.svg";
import FoldersIcon from "@/assets/icons/svg/folders.icon.svg";
import UsersIcon from "@/assets/icons/svg/users.icon.svg";
import BookOpenIcon from "@/assets/icons/svg/book-open.icon.svg";
import GraduationCapIcon from "@/assets/icons/svg/graduation-cap.icon.svg";
import VideoIcon from "@/assets/icons/svg/video.icon.svg";
import TelevisionIcon from "@/assets/icons/svg/television.icon.svg";
import ClockIcon from "@/assets/icons/svg/clock.icon.svg";
import SettingsIcon from "@/assets/icons/svg/settings.icon.svg";
import LogoutIcon from "@/assets/icons/svg/logout.icon.svg";

export default {
  components: {
    HomeIcon,
    FoldersIcon,
    UsersIcon,
    BookOpenIcon,
    GraduationCapIcon,
    VideoIcon,
    TelevisionIcon,
    ClockIcon,
    SettingsIcon,
    LogoutIcon,
  },
  methods: {
    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
    },

    async onLogoutIntent() {
      this.closeNav();
      try {
        let logOut = await this.$confirm(
          "You will be immediately logged out of your account, Continue?",
          "Confirm Log out",
          {
            confirmButtonText: "Logout",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );

        if (logOut) {
          this.logOut();
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars
      }
    },

    async logOut() {
      let request = await this.httpRequest({
        url: "/staff/logout",
        method: "POST",
        showFullScreenLoader: true,
        fullScreenLoaderMessage: "Logging you out...",
      });
      if (
        request &&
        request.success &&
        request.message == "LOGOUT_SUCCESSFUL"
      ) {
        this.$store.commit("LOGOUT_USER");
        this.$router.replace({ path: "/login" });
      }
    },
  },
};
</script>

<style scoped>
.theLists {
  margin: 50px 0px;
  padding: 0px;
}
ul {
  padding: 0px;
}
ul li {
  list-style: none;
  text-align: left;
  font-weight: 500;
}
ul li i {
  margin-right: 5px;
}

.side-link {
  color: rgba(0, 0, 0, 0.74);
  font-weight: 500;
  padding: 10px 30px;
  cursor: pointer;
  transition: 0.5s;
}

.side-link.active {
  border-left: 6px solid var(--vu-red);
  color: var(--el-app-primary) !important;
  font-weight: 600;
}

.side-link:hover {
  background-color: #eeeeee;
  padding: 10px 40px;
  transition: 0.5s;
  color: #74a4c5;
}

.svg-icon {
  height: 20px;
  position: relative;
  bottom: -5px;
  left: -8px;
  padding-left: 7px;
  color: #000000b5;
}

/* Active color for svg */
.active .svg-icon {
  color: var(--el-app-primary);
}
</style>
