export default async (): Promise<{
  os: string;
  version: string;
  mobile: boolean;
  model: string;
} | null> => {
  try {
    //@ts-expect-error Property 'userAgentData' does not exist on type 'Navigator'
    if (navigator.userAgentData) {
      //@ts-expect-error Property 'userAgentData' does not exist on type 'Navigator'
      const data = await navigator.userAgentData.getHighEntropyValues([
        "model",
        "platform",
        "platformVersion",
        "mobile",
      ]);

      return {
        os: data.platform,
        version: data.platformVersion,
        mobile: data.mobile,
        model: data.model,
      };
    }
    return null;
  } catch (error) {
    return null;
  }
};
