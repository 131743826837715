import "./registerServiceWorker";

//CSS IMPORTS
import "vue-plyr/dist/vue-plyr.css";
import "./assets/css/skeleton-elements.css";
import "./assets/fonts/stylesheet.css";
import "./assets/css/style.min.css";
import "./assets/css/main.css";

import "firebase/firestore";
import "firebase/analytics";
import firebase from "firebase/app";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { compareAppVersion } from "./helpers/compareAppVersion";
import axios from "axios";
import VueAxios from "vue-axios";
import VuePlyr from "vue-plyr";
import vBlur from "v-blur";
import Ripple from "vue-ripple-directive";
import { DataTables, DataTablesServer } from "vue-data-tables";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
import Rollbar from "rollbar";
import VueMask from "v-mask";
import {
  useShowErrorMessage,
  useShowSuccessMessage,
  useShowWarningMessage,
} from "./composables/use-notifications";

//ELEMENT UI IMPORTS
import {
  Table,
  TableColumn,
  Pagination,
  Button,
  Progress,
  DatePicker,
  Dialog,
  Select,
  Option,
  Notification,
  TimePicker,
  Loading,
  Tabs,
  MessageBox,
  Input,
  Tooltip,
  TabPane,
  Upload,
  Popover,
  Carousel,
  CarouselItem,
  Row,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  InputNumber,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Form,
  FormItem,
  Empty,
  Result,
  Switch,
} from "element-ui";

locale.use(lang);
Vue.use(vBlur);
Vue.use(VueMask);

Vue.prototype.$rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: false /* process.env.NODE_ENV == "production" */,
  captureUnhandledRejections: false /* process.env.NODE_ENV == "production" */,
  environment: process.env.NODE_ENV,
  captureIp: true,
  enabled: false /* process.env.NODE_ENV == "production" */,
});

var firebaseConfig = {
  apiKey: "AIzaSyAW-yb32FVJVr15nyMaeiJ8EZkH2wRrxNs",
  authDomain: "victoria-university.firebaseapp.com",
  projectId: "victoria-university",
  storageBucket: "victoria-university.appspot.com",
  messagingSenderId: "213814191650",
  appId: "1:213814191650:web:e4584bec9d8f2e57d80c7b",
  measurementId: "G-038VC8G2DR",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.analytics();

Vue.use(VuePlyr);
Vue.use(VueAxios, axios);

Vue.directive("ripple", Ripple);
Vue.prototype.$notify = Notification;
Vue.use(DataTables);
Vue.use(DataTablesServer);
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;
Vue.use(Tooltip);
Vue.use(Upload);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Button);
Vue.use(Progress);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Dialog);
Vue.use(Select);
Vue.use(Button);
Vue.use(Option);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);

Vue.use(Table);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Input);
Vue.use(Popover);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Row);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(InputNumber);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Empty);
Vue.use(Result);
Vue.use(Switch);

axios.defaults.baseURL =
  process.env.VUE_APP_API_LOCAL_URL || process.env.VUE_APP_API_BASE_URL;

axios.defaults.withCredentials = true;
const appVersion = process.env.VUE_APP_VERSION;

//Intercept Requests to add Authorization Header
axios.interceptors.request.use((config) => {
  //Only add the Authorization Header if the url they are hitting is to from amazonaws.com as those will be rejected by AWS
  if (config.url && config.url.includes("amazonaws.com")) {
    return config;
  }

  //Add Authorization Header
  config.headers["Authorization"] = `Bearer ${store.state.accessToken}`;

  // Add appVersion to the request headers
  config.headers["App-Version"] = appVersion;

  return config;
});

//INTERCEPT RESPONSES TO CHECK FOR ENW BEARER TOKEN OR 401 UNAUTHORIZED RESPONSES
axios.interceptors.response.use(
  function (response) {
    compareAppVersion(response);

    //Always Check if Response has new bearer token
    let newBearerToken = response.data && response.data.newBearerToken;

    //If we got a new Access Token, then it means the previous one expired so we need to obtain the new one
    if (newBearerToken) {
      store.commit("REFRESH_ACCESS_TOKEN", { accessToken: newBearerToken });
    }
    return response;
  },
  function (error) {
    const response = error.response;

    if (response) {
      compareAppVersion(response);
      //Always Check if Authorization Header has new bearer token
      let newBearerToken = response.data && response.data.newBearerToken;

      //If we got a new Access Token, then it means the previous one expired so we need to obtain the new one
      if (newBearerToken) {
        store.commit("REFRESH_ACCESS_TOKEN", { accessToken: newBearerToken });
      }

      //If the Status COde is 401
      if (response.status == 401) {
        store.commit("SET_REDIRECT_URL", router.currentRoute.fullPath);
        //Logout User Immediately
        store.commit("LOGOUT_USER");
        router.replace({ path: "/login" });
      }
    }

    return Promise.reject(error);
  }
);

Vue.mixin({
  methods: {
    showFailedMessage(title, msg) {
      const h = this.$createElement;
      this.$notify({
        title,
        message: h("span", { style: "color: black; text-align:left" }, msg),
        type: "error",
      });
    },
    showSuccessMessage(title, msg) {
      const h = this.$createElement;
      this.$notify({
        title,
        message: h(
          "span",
          {
            style: "color: black;",
          },
          msg
        ),
        type: "success",
      });
    },
    showWarningMessage(title, msg, duration?: number) {
      const h = this.$createElement;
      this.$notify({
        title,
        message: h(
          "span",
          {
            style: "color: black;",
          },
          msg
        ),
        type: "warning",
        duration: duration || undefined,
      });
    },
    async httpRequest({
      url,
      method,
      body,
      loadingPropertyName,
      errorLoadingPropertyName,
      showFullScreenLoader,
      fullScreenLoaderMessage,
      isSilent,
      showSuccessMessage,
      persistSuccessNotification,
    }) {
      {
        let loadingIndicator;
        try {
          // IMPORTANT!!, before making the HTTP Call always first wait for the latest DOM updates otherwise this causes weird issues on reload
          await this.$nextTick();
          if (loadingPropertyName) {
            //@ts-ignore
            this[loadingPropertyName] = true;
          }

          if (errorLoadingPropertyName) {
            //@ts-ignore
            this[errorLoadingPropertyName] = false;
          }

          if (showFullScreenLoader) {
            loadingIndicator = Loading.service({
              lock: true,
              text: fullScreenLoaderMessage || "Please wait...",
              background: "rgba(0, 0, 0, 0.7)",
            });
          }

          const { data } = await this.$http(url, {
            method: method,
            data: body,
          });

          if (isSilent) {
            return data;
          }

          if (data.success && showSuccessMessage) {
            useShowSuccessMessage(
              "Success",
              (data as any).message,
              persistSuccessNotification
            );
          } else if (!data.success) {
            useShowWarningMessage("Not Allowed", (data as any).message);
          }

          return data;
        } catch (err) {
          if (errorLoadingPropertyName) {
            //@ts-ignore
            this[errorLoadingPropertyName] = true;
          }

          const error = err as any;

          if (isSilent) {
            return error?.response?.data;
          }

          if (error.message == "Network Error") {
            useShowWarningMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
            return;
          }

          if (error.response) {
            // If the error response is in the 400 range, we will show a warning message
            if (error.response.status >= 400 && error.response.status < 500) {
              useShowWarningMessage("Not Allowed", error.response.data.message);

              return error.response.data.message;
            }
          }

          // else
          // showErrorMessage('Error', "Unexpected error occurred.")
          useShowErrorMessage("Error", "An unexpected error occurred.");
          return error.response.data.message;
        } finally {
          if (loadingPropertyName) {
            //@ts-ignore
            this[loadingPropertyName] = false;
          }

          loadingIndicator?.close();
        }
      }
    },
  },
});

// Vue.config.errorHandler = (err, vm, info) => {
//   //@ts-ignore
//   vm.$rollbar.warning("LECTURER FRONT END: ERROR HANDLER", err, info);
//   throw err; // rethrow
// };

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
